<template>
  <div data-app class="w-100 my-5">
    <div class="box-white d-flex flex-column">
      <div class="row w-100 mt-5 ml-1 mr-2 vertical-center">
        <span class="col-sm-2 m-1 font-bold font-12 alpha-07">{{ $t('title') }}</span>
        <input class="col-sm m-1 font-12" v-model="title" />
      </div>
      <div class="row w-100 mt-5 ml-1 mr-2 vertical-center">
        <span class="col-sm-2 m-1 font-bold font-12 alpha-07">{{ $t('message') }}</span>
        <textarea class="col-sm m-1 font-12" v-model="message" />
      </div>
      <div class="my-5 d-flex justify-content-end">
        <v-btn class="mx-5 button-normal" small @click="visibleEventDialog = true" :disabled="eventList.length === 0">
          <span>{{ $t('add_event') }}</span>
        </v-btn>
        <v-btn class="mx-5 button-red" small @click="selectedEventList = []" :disabled="selectedEventList.length === 0">
          <span>{{ $t('clear_selection') }}</span>
        </v-btn>
      </div>
      <div style="width: 100%; background-color: #4B104F;">
        <br/>
        <a style="margin: 15px;" href="https://www.eventboxde.com" target="_blank">
          <img style="width: 150px;" :src="assets.logo_light" />
        </a>
        <span style="margin: 10px; color: #FFFFFF; font-size: 18px; font-weight: bold; float: right;">EVENTNEWS</span>
      </div>
      <div style="text-align: center;">
        <br/><br/>
        <h2 style="white-space: pre-line; margin: 30px;">{{ title || `[${$t('title')}]` }}</h2>
        <h4 style="white-space: pre-line; margin: 10px 20px;">{{ message || `[${$t('message')}]` }}</h4>
        <br/>
        <div v-for="(item, index) in selectedEventList" :key="(item, index)">
          <br/><br/><br/>
          <div style="background-color: #000000; width: min(90%, 480px); margin:0 auto; text-align: left;">
            <img style="width: calc(100% - 10px); margin: 5px;" :src="item.photoUrl" :alt="item.name" />
            <p style="color: #FFFFFF; margin: 10px; font-size: 12px;">{{ item.date }}</p>
            <span style="color: #FFFFFF; margin: 0px 10px; font-size: 15px; font-weight: bold;">{{ item.name }}</span>
            <br/>
            <button style="background-color: #4B104F; color: #FFFFFF; border-radius: 10px; margin: 10px; padding: 10px 20px;" :onclick="`window.open('${item.eventLink}', '_blank');`">Ticket</button>
            <br/><br/>
          </div>
        </div>
        <div style="background-color: #4B104F; width: 100%; margin-top: 50px;">
          <br/><br/><br/>
          <div>
            <a style="color: #FFFFFF; font-size: 12px;" href="https://www.youtube.com/watch?v=lfFzx5zCsS4" target="_blank">
              <img style="width: 30px;" :src="assets.youtube" />
            </a>
            <span style="padding: 0px 10px;">&nbsp;</span>
            <a style="color: #FFFFFF; font-size: 12px;" href="https://www.facebook.com/Eventbox-NB-112245895129691" target="_blank">
              <img style="width: 30px;" :src="assets.facebook" />
            </a>
            <span style="padding: 0px 10px;">&nbsp;</span>
            <a style="color: #FFFFFF; font-size: 12px;" href="https://www.instagram.com/eventbox.germany?igsh=bHNlb2JpdnR4azg5&utm_source=qr" target="_blank">
              <img style="width: 30px;" :src="assets.instagram" />
            </a>
          </div>
          <br/><br/>
          <p style="color: #FFFFFF; font-size: 12px;">&copy; {{ new Date().getFullYear() }} Eventbox All rights reserved.</p>
          <br/>
          <div>
            <a style="color: #FFFFFF; font-size: 12px;" href="https://www.eventboxde.com/terms" target="_blank">Nutzungsbedingungen</a>
            <span style="color: #FFFFFF; padding: 0px 10px;">&nbsp;|&nbsp;</span>
            <a style="color: #FFFFFF; font-size: 12px;" href="https://www.eventboxde.com/privacy" target="_blank">AGB + Datenschutzerklärung</a>
          </div>
          <br/>
          <div>
            <a style="color: #FFFFFF; font-size: 12px;" href="#" target="_blank">Clicke hier, um das Abo zu beenden und E-Mail vom Absender abzuschalten.</a>
          </div>
          <br/>
        </div>
      </div>
      <div class="my-5 d-flex justify-content-end">
        <v-btn class="mx-5 button-normal" small @click="sendEmailTask" :disabled="(!title || !message || items.length === 0)">
          <span>{{ $t('send') }}</span>
        </v-btn>
      </div>
    </div>
    <div class="my-5 row">
      <div class="col-xl-4 col-6" v-for="(item, index) in genres" :key="(item, index)">
        <div :class="`my-1 genre-${item.name === genre ? 'selected' : 'normal'} vertical-center`" @click="genre = item.name">
          <img :src="item.icon" :height="15" />
          <span class="ml-5 font-12">{{ item.name }}</span>
        </div>
      </div>
    </div>
    <div class="my-5" v-if="userList">
      <div class="my-5 row vertical-center" v-if="isMobile">
        <span class="col-3 font-bold font-12 alpha-07">&nbsp;&nbsp;Ages</span>
        <input class="m-1 col-3 font-12" maxlength="2" v-model="fromAge" />
        <span class="font-bold font-12 alpha-07">&nbsp;-&nbsp;</span>
        <input class="m-1 col-3 font-12" maxlength="2" v-model="toAge" />
        <v-btn class="m-1" icon small @click="refreshUserList">
          <i class="position-absolute fa fa-search font-20 color-purple" />
        </v-btn>
      </div>
      <div class="my-5 vertical-center d-flex justify-content-end" v-else>
        <span class="font-bold font-12 alpha-07">Ages</span>
        <input class="m-1 font-12" maxlength="2" v-model="fromAge" />
        <span class="font-bold font-12 alpha-07">&nbsp;-&nbsp;</span>
        <input class="m-1 font-12" maxlength="2" v-model="toAge" />
        <v-btn class="m-1" icon small @click="refreshUserList">
          <i class="position-absolute fa fa-search font-20 color-purple" />
        </v-btn>
      </div>
      <span class="ml-5 font-bold font-12">Users: {{ rows }}</span>
      <div class="mt-5" v-for="(item, index) in items.slice(Math.max((pageIndex - 1) * PAGE_LIMIT_20, 0), Math.min(pageIndex * PAGE_LIMIT_20, rows))" :key="(item, index)">
        <v-card class="my-2 row box-white">
          <div class="m-2 row">
            <span class="col-sm-1 m-1">{{ (pageIndex - 1) * PAGE_LIMIT_20 + index + 1 }}</span>
            <img class="circle-image-30 m-1" :src="item.thumbnailUrl || item.photoUrl || assets.profile" />
            <span class="col-sm-2 m-1 font-bold">{{ item.userName }}</span>
            <span class="col-sm-3 m-1">{{ item.email }}</span>
            <span class="col-sm-2 m-1 font-bold">{{ item.genre }}</span>
            <span class="col-sm-2 m-1">{{ item.birthday }}</span>
            <span class="col-sm m-1">{{ getDateStringFromTimestamp(item.lastOnlineAt) }}</span>
          </div>
        </v-card>
      </div>
      <b-pagination
        class="my-5"
        v-model="pageIndex"
        v-if="items.length > 0"
        :total-rows="rows"
        :per-page="PAGE_LIMIT_20" />
    </div>
    <div class="py-40 center" v-else>
      <p>{{ $t('please_wait') }}</p>
      <b-spinner type="grow"></b-spinner>
    </div>
    <!-- Start Select Event Dialog -->
    <v-dialog v-model="visibleEventDialog" scrollable width="800px">
      <v-card>
        <v-card-title class="d-flex flex-column">
          <div class="w-100 d-flex justify-content-between">
            <span class="font-bold font-18">{{ $t('select_event') }}</span>
            <v-btn class="ml-auto" icon small @click="visibleEventDialog = false; keyword = '';">
              <i class="flaticon-cancel font-bold font-25 color-black"></i>
            </v-btn>
          </div>
          <div class="w-100 my-1 vertical-center">
            <input class="w-100" v-model="keyword" />
            <v-btn class="ml-2" icon small @click="keyword = ''" :disabled="!keyword">
              <i :class="`flaticon-circle ${keyword.length > 0 ? 'color-blue' : ''}`"></i>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text>
          <div class="m-2 box-white pointer vertical-center d-flex" v-for="(item, index) in eventList" :key="index" @click="onEventSelected(item)">
            <img :width="`${isMobile ? '50px' : '100px'}`" :src="item.photoUrl" />
            <div class="ml-2 d-flex flex-column">
              <span :class="`${isMobile ? 'font-10' : 'font-12'} color-blue`">{{ getDateStringFromTimestamp(item.startAt) }} - {{ getDateStringFromTimestamp(item.endAt) }}</span>
              <span :class="`font-bold ${isMobile ? 'font-12' : 'font-15'}`"> {{ item.name }}</span>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- End Select Event Dialog -->
  </div>
</template>

<style lang="scss">
  .genre-normal {
    padding: 10px 15px !important;
    border-radius: 10px !important;
    border: 2px solid #E5E5E5 !important;
    background-color: white !important;
    cursor: pointer !important;
    box-shadow: 4px 8px 20px rgba(34,90,89,0.2) !important;
    -moz-box-shadow: 4px 8px 20px rgba(34,90,89,0.2) !important;
    -webkit-box-shadow: 4px 8px 20px rgba(34,90,89,0.2) !important;
  }
  .genre-selected {
    padding: 10px 15px !important;
    border-radius: 10px !important;
    border: 2px solid #4B104F !important;
    background-color: white !important;
    box-shadow: 4px 8px 20px rgba(34,90,89,0.2) !important;
    -moz-box-shadow: 4px 8px 20px rgba(34,90,89,0.2) !important;
    -webkit-box-shadow: 4px 8px 20px rgba(34,90,89,0.2) !important;
  }
</style>

<script>
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../main';
import { getDateStringFromTimestamp, getEventLink, isFutureTimestamp, showLoading } from '../../../functions';

import profile from '@/assets/image/profile.jpg';
import genre_hiphop_rap from '@/assets/image/genre_hiphop_rap.png';
import genre_rock from '@/assets/image/genre_rock.png';
import genre_pop from '@/assets/image/genre_pop.png';
import genre_electro from '@/assets/image/genre_electro.png';
import genre_dj_dance from '@/assets/image/genre_dj_dance.png';
import logo_light from '@/assets/image/logo_light.png';
import youtube from '@/assets/image/youtube.png';
import facebook from '@/assets/image/facebook.png';
import instagram from '@/assets/image/instagram.png';

export default {
  name: 'SendEmail',
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    userList() {
      if (this.$store.state.userList) {
        return this.$store.state.userList.filter(element => element.userType === 0 && element.lastOnlineAt && element.email && element.email.indexOf('@social.without.email') === -1);
      } else {
        return null;
      }
    },
    liveEventList() {
      if (this.$store.state.eventList) {
        return this.$store.state.eventList.filter(element => isFutureTimestamp(element.endAt)).sort((a, b) => (a.createdAt.seconds < b.createdAt.seconds) ? 1 : -1);
      } else {
        return [];
      }
    },
    rows() {
      return this.items.length;
    }
  },
  watch: {
    userList(newList, oldList) {
      const oldCount = oldList ? oldList.length : 0;
      const newCount = newList ? newList.length : 0;
      if (oldCount !== newCount) {
        this.refreshUserList();
      }
    },
    liveEventList(newList, oldList) {
      const oldCount = oldList ? oldList.length : 0;
      const newCount = newList ? newList.length : 0;
      if (oldCount !== newCount) {
        this.refreshEventList();
      }
    },
    genre() {
      this.refreshUserList();
    },
    keyword() {
      this.refreshEventList();
    }
  },
  data() {
    return {
      assets: {
        profile,
        logo_light,
        youtube,
        facebook,
        instagram
      },
      genres: [
        {
          icon: genre_hiphop_rap,
          name: 'HipHop/Rap'
        },
        {
          icon: genre_rock,
          name: 'Rock'
        },
        {
          icon: genre_pop,
          name: 'Pop'
        },
        {
          icon: genre_electro,
          name: 'Electro'
        },
        {
          icon: genre_dj_dance,
          name: 'DJ/Dance'
        },
      ],
      title: '',
      message: '',
      genre: 'HipHop/Rap',
      fromAge: '',
      toAge: '',
      visibleEventDialog: false,
      pageIndex: 1,
      keyword: '',
      eventList: [],
      selectedEventList: [],
      items: []
    }
  },
  mounted() {
    this.refreshUserList();
    this.refreshEventList();
  },
  methods: {
    getEventLink,
    getDateStringFromTimestamp,
    refreshUserList() {
      if (this.userList) {
        const curYear = new Date().getFullYear();
        const fromAge = Number.parseInt(this.fromAge) || 0;
        const toAge = Number.parseInt(this.toAge) || 0;
        const fromYear = toAge > 0 ? curYear - toAge : 0;
        const toYear = fromAge > 0 ? curYear - fromAge : curYear;
        const items = this.userList.filter(element => this.filterUser(element, fromYear, toYear));
        items.sort((a, b) => (a.lastOnlineAt.seconds < b.lastOnlineAt.seconds) ? 1 : -1);
        this.pageIndex = 1;
        this.items = items;
      } else {
        this.items = [];
      }
    },
    refreshEventList() {
      const keyword = this.keyword.toLowerCase();
      this.eventList = this.liveEventList.filter(element => element.name.toLowerCase().includes(keyword));
    },
    filterUser(userInfo, fromYear, toYear) {
      if (userInfo.genre !== this.genre) {
        return false;
      }
      if (userInfo.birthday && userInfo.birthday.length > 4) {
        const year = Number.parseInt(userInfo.birthday.substring(0, 4)) || 0;
        return fromYear <= year && toYear >= year;
      }
      return false;
    },
    onEventSelected(eventInfo) {
      this.visibleEventDialog = false;
      this.keyword = '';
      this.selectedEventList.push({
        eventLink: getEventLink(eventInfo),
        photoUrl: eventInfo.photoUrl,
        date: `${getDateStringFromTimestamp(eventInfo.startAt)} - ${getDateStringFromTimestamp(eventInfo.endAt)}`,
        name: eventInfo.name
      });
    },
    sendEmailTask() {
      if (!this.title) {
        this.$toast.info('Please enter title.');
        return;
      }
      if (!this.message) {
        this.$toast.info('Please enter message.');
        return;
      }
      if (!confirm(this.$t('confirm_send_message', [this.rows]))) {
        return;
      }
      const toList = [];
      this.items.forEach(info => {
        toList.push(info.email);
      });
      const params = {
        functionName: 'sendEmail',
        subject: this.title,
        message: this.message,
        eventList: this.selectedEventList,
        toList: toList
      };
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, this.ADMIN_FUNCTION);
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          this.$toast.success(this.$t('success'));
        } else {
          this.$toast.error(response.data);
        }
        this.title = '';
        this.message = '';
        this.selectedEventList = [];
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    }
  }
};
</script>